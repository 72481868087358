.container {
  position: fixed;
  width: 100vw;
  bottom: 0;
  padding: 20px 40px;
  background: white;
  display: flex;
  align-items: center;
  text-align: right;
  justify-content: space-between;
  box-sizing: border-box;
}

.button, .container {
  font-family: 'Andale Mono';
  font-size: 19px;
}

.button {
  padding: 16px;
  background: linear-gradient(rgb(245, 169, 244) 0px, rgb(245, 169, 244) 30%, rgb(245, 169, 244) 30.01%, rgb(255, 170, 151) 100%);
  cursor: pointer;
  color: white;
  font-weight: 600;
  white-space: nowrap;
}

@media only screen and (max-width: 1200px) {
  .container {
    padding: 16px;
  }
}