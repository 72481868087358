.containerOne {
  background: #132026;
  color: white;
}

.containerTwo {
  background: #ebebeb;
  color: black;
}

.wrap {
  max-width: 1280px;
  margin: auto;
  padding: 7%;
}

.title {
  font-family: 'Andale Mono';
  font-size: 27px;
  margin-bottom: 16px;
}

.input {
  font-size: 19px;
  padding: 8px;
  width: 100%;
  margin: 25px 0;
  font-family: 'Andale Mono';
}

.button {
  background-color: #f77be0;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
}

.inputWrap {
  display: flex;
  align-items: center;
}

.inputWrap img {
  height: 50px;
  margin-right: 5px;
}