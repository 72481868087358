.container {
  background: blue;
  padding: 5% 0;
  width: 100%;

}

.wrap {
  max-width: 1280px;
  margin: auto;
  display: flex;
  flex-direction: row;
  padding: 10%;
}

.container img {
  max-width: 70%;
}

.text {
  font-size: 19px;
  font-family: 'Andale Mono';
  padding-left: 50px;
  margin-top: 30px;
  letter-spacing: .1em;
}

.text div {
  margin-bottom: 4px;
}

.container input {
  padding: 8px;
  opacity: 1;
  width: 30px;
  font-family: 'Andale Mono';
  font-size: 19px;
  margin-right: 16px;
}

.inputWrap {
  margin-top: 30px;
}

.inputWrap, .inputWrap div {
  display: flex;
  font-family: 'Andale Mono';
  font-size: 19px;
  -webkit-user-select: none;  /* Chrome all / Safari all */
  -moz-user-select: none;     /* Firefox all */
  -ms-user-select: none;      /* IE 10+ */
  user-select: none;
}

.inputWrap div {
  padding: 16px;
  padding-left: 0;
  cursor: pointer;
}

@media only screen and (max-width: 1200px) {
  .wrap {
    flex-direction: column;
  }
  .text {
    padding-left: 0;
  }
  .container img {
    object-fit: contain;
    max-width: 100%;
  }
}