.container {
  padding: 65px 50px;
  width: 100vw;
  margin: auto;
  max-width: 1280px;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.container ul {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.container li {
  display: table-cell;
  font-size: 21px;
  text-align: center;
  padding-bottom: 12px;
  text-align: center;
}

.container a, .connect {
  font-family: 'Andale Mono';
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;
}

.connect {
  cursor: pointer;
}
