.container {
  background: #000;
  padding: 10% 0;
  color: white;
  font-family: 'Myriad Pro';
  font-size: 22px;
  width: 100vw;
}

.wrap {
  margin-left: 50%;
  border-left: 3px solid white;
  padding-left: 32px;
  padding-bottom: 7px;
}

.container img {
  width: 40px;
}

.container li {
  float: left;
  padding: 15px 15px 0 0;
  list-style: none;
}

.container ul {
  margin-bottom: 60px;
}

@media only screen and (max-width: 1200px) {
  .wrap {
    margin-left: 32px;
  }
}