.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #00000099;
  z-index: 9;
}

.container {
  position: fixed;
  width: 96vw;
  height: 96vh;
  background: white;
  box-sizing: border-box;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  padding: 32px;
  font-family: 'Myriad Pro';
  font-size: 17px;
  overflow-y: scroll;
  max-width: 1000px;
  z-index: 99;
}

.title {
  font-family: 'Andale Mono';
  font-size: 19px;
  line-height: 1.5em;
  font-weight: 600;
  margin-bottom: 32px;
}

.container div, .container p {
  margin-bottom: 12px;
}

.container div {
  margin-top: 32px;
  font-weight: 600;
  font-family: 'Andale Mono';
}

.button {
  color: #73a1ff;
  font-weight: 600;
  font-family: 'Andale Mono';
  cursor: pointer;
  margin-top: 42px !important;
  font-size: 21px;
}