.container {
  font-size: 19px;
  position: relative;
}

.wrap {
  max-width: 1280px;
  margin: auto;
  padding: 10%;
  box-sizing: border-box;
}

.container video {
  width: 100%;
  height: auto;
}

.container h2 {
  font-family: 'Andale Mono';
  font-size: 32px;
  margin-bottom: 30px;
  text-transform: uppercase;
}

.container p {
  margin-top: 30px;
  margin-bottom: 30px;
  white-space: pre-line;
}

.container img {
  text-align: center;
  max-width: 100%;
}

.bgImg {
  position: absolute;
  top: 0;
  right: 0;
}

@media only screen and (max-width: 1200px) {
  .bgImg {
    display: none;
  }
  .wrap {
    padding: 10% 16px;
  }
}

@media only screen and (min-width: 1200px) {

  .container {
    width: 100%;
    clear: both;
    float: left;
  }

  .container h2, .container p {
    width: 39%;
    float: right;
    padding-left: 20px;
    box-sizing: border-box;
  }

  .container p {
    margin-top: 0;
  }

  .container video {
    width: 60%;
    float: left;
  }

  .container img {
    margin-top: 30px;
    max-width: 60%;
  }

}