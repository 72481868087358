.container {
  background: -webkit-gradient(linear,left top,left bottom,from(#f5a9f4),to(#ffaa97));
  background: -webkit-linear-gradient(top,#f5a9f4 0,#ffaa97 100%);
  background: linear-gradient(180deg,#f5a9f4 0,#ffaa97 100%);
  height: 100vh;
  padding-top: 300px;
  text-align: center;
  box-sizing: border-box;
  overflow: hidden;
}

.container h1 {
  font-size: 27px;
  font-family: 'Andale Mono';
  text-transform: uppercase;
  color: white;
  font-weight: 600;
  margin-bottom: 12px;
  margin-top: 10px;
}

.container div {
  font-family: 'Andale Mono';
  color: white;
  font-weight: 500;
}

.bgImage {
  position: absolute;
  bottom: 0;
  right: 0;
}

@media only screen and (max-width: 1200px) {
  .bgImage {
    display: none;
  }
}