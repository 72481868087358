.popup {
  position: fixed;
  top: 15px;
  background: white;
  width: 95%;
  max-width: 500px;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  padding: 32px;
  box-sizing: border-box;
}

.popup input {
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 8px;
}

.button {
  padding: 8px;
  border-radius: 4px;
  background-color: #bdddf1;
  display: inline-flex;
  margin: 16px 0;
  font-family: 'Andale Mono';
  font-weight: 600;
  cursor: pointer;
}
